import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ButtonComponent } from '../button/button.component';
import { SelectCategoryMobileModalPage } from './modal/select-category-mobile/select-category-mobile-modal.page';
import { SelectCategoryDesktopModalPage } from './modal/select-category-desktop/select-category-desktop-modal.page';
import { ComponentRef } from '@ionic/core';
import { ModalService } from '../../services/modal.service';
import { AppService } from '../../services/app.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-book-categories-btn',
  templateUrl: './book-categories-btn.component.html',
  imports: [ButtonComponent],
})
export class BookCategoriesBtnComponent {
  constructor(
    private modalService: ModalService,
    private appService: AppService,
  ) {}

  async openCategoriesModal(): Promise<void> {
    const component = this.appService.isMobile$.value ? SelectCategoryMobileModalPage : SelectCategoryDesktopModalPage;
    const cssClass = this.appService.isMobile$.value ? 'mobile-select-category' : 'desktop-select-category';
    const breakPoints = this.appService.isMobile$.value ? { breakpoints: [0, 1], initialBreakpoint: 1 } : {};

    const categoriesModal = await this.modalService.create({
      component: component as ComponentRef,
      cssClass: 'wr-modal ' + cssClass,
      ...breakPoints,
    });

    await categoriesModal.present();
  }
}
