<app-header [showBackButton]="false" />
<ion-content>
  <div class="main-container app-content-max-width">
    <div class="filters-row">
      <app-search (searchFocused)="searchFocused($event)" >
        <ng-container slot="buttons">
          <app-book-filters-btn [@fadeIn] data-cy="search-book-filters" />
          <app-book-categories-btn />
        </ng-container>
      </app-search>
    </div>
    <div [class.hidden]="isSearchFocused" class="shelves" data-cy="home-shelves-container">
      @if ((profileState.currentProfile$ | async)?.id) {
        <app-book-shelf-drawer [type]="shelfType.ContinueReading" data-cy="home-continue-reading-shelf" />
      }

      <app-book-shelf-drawer [type]="shelfType.FeaturedActive" data-cy="home-featured-active-shelf" />
      <app-book-shelf-drawer [type]="shelfType.Collection" data-cy="home-collection-shelf" />

      @if ((profileState.currentProfile$ | async)?.isFamilyProfile === true) {
        <app-book-shelf-drawer [type]="shelfType.BedStory" data-cy="home-bed-story-shelf" />
      }

      @if (vroomTipsService.shouldDisplayTips$ | async) {
        <app-tips />
      }

      <app-book-shelf-drawer [type]="shelfType.Popular" data-cy="home-popular-shelf" />
      <app-book-shelf-drawer [type]="shelfType.New" data-cy="home-new-shelf" />
      <app-book-categories-shelves data-cy="home-categories-shelf" />
    </div>
  </div>
</ion-content>
