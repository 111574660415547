import { inject } from '@angular/core';
import { UserState, UserType } from '../../store/user.state';
import { AppService } from '../../services/app.service';
import { CanActivateFn } from '@angular/router';
import { ProfileState } from '../../store/profile.state';
import { AuthenticationModalPage } from '../../modals/authentication/authentication-modal.page';
import { NavigationService } from '../../services/navigation.service';
import { ComponentRef } from '@ionic/core';
import { ModalService } from '../../services/modal.service';

export const googleAuthenticationPageGuard: CanActivateFn = async () => {
  const userState = inject(UserState);
  const appService = inject(AppService);
  const modalService = inject(ModalService);
  const navigationService = inject(NavigationService);
  const profileState = inject(ProfileState);

  const params = new URL(window.location.toString()).searchParams;

  if (!params.get('code')) {
    void navigationService.navigateRoot('authentication');
    return false;
  }

  if (appService.isMobile$.value) {
    void navigationService.navigateRoot('authentication', {
      state: {
        googleOAuth: {
          isOauth: true,
          state: params.get('state'),
          code: params.get('code'),
        },
      },
    });

    return false;
  } else {
    // Open authentication modal
    void navigationService.navigateRoot('/home');
    const loginCreateAccountModal = await modalService.create({
      component: AuthenticationModalPage as ComponentRef,
      cssClass: 'wr-modal authentication',
      componentProps: {
        googleOAuth: {
          isOauth: true,
          state: params.get('state'),
          code: params.get('code'),
        },
      },
    });

    await loginCreateAccountModal.present();

    // Force go to profile creation
    await loginCreateAccountModal.onDidDismiss().then(() => {
      if (userState.user$.value.userType !== UserType.GUEST && !profileState.currentProfile$.value) {
        void navigationService.navigateRoot(profileState.profiles$.value.length > 1 ? '/profile/select' : '/profile/create', {
          state: { hideHeader: true },
        });
      }
    });

    return false;
  }
};
