import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { AnalyticsEventType, ModalViewEvents } from '../../../../services/analytics/analytics.model';
import { AnalyticsService } from '../../../../services/analytics/analytics.service';
import { IonRadio, IonRadioGroup } from '@ionic/angular/standalone';
import { ButtonComponent } from '../../../button/button.component';
import { BooksService } from '../../../../services/books.service';
import { LanguageService } from '../../../../services/language.service';
import { BookCategory } from '../../../../models/book.model';
import { Translatable } from '../../../../models/translation.model';
import { filter, take } from 'rxjs';
import { NavigationService } from '../../../../services/navigation.service';
import { ModalService } from '../../../../services/modal.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-select-category-desktop-modal',
  templateUrl: './select-category-desktop-modal.page.html',
  styleUrls: ['./select-category-desktop-modal.page.scss'],
  imports: [TranslateModule, AsyncPipe, ButtonComponent, IonRadio, IonRadioGroup],
})
export class SelectCategoryDesktopModalPage implements OnInit {
  constructor(
    public booksService: BooksService,
    public languageService: LanguageService,
    private analyticsService: AnalyticsService,
    private navigationService: NavigationService,
    private ref: ChangeDetectorRef,
    private modalService: ModalService,
  ) {}

  selectedCategory?: BookCategory;

  ngOnInit(): void {
    this.booksService.categoriesBooks$
      .pipe(
        filter(categories => categories.length > 0),
        take(1),
      )
      .subscribe(categories => {
        this.selectedCategory = categories[0] || undefined;
        this.ref.detectChanges();
      });
  }

  ionViewDidEnter(): void {
    this.analyticsService.sendEvent(AnalyticsEventType.InScreen, { screenName: ModalViewEvents.SelectCategoryModal });
  }

  async onSelectedCategoryChange(event: CustomEvent): Promise<void> {
    this.selectedCategory = event.detail.value;
  }

  async applyCategoryChange(): Promise<void> {
    if (this.selectedCategory) {
      void this.navigationService.navigate('shelf/category/' + this.selectedCategory.id, {
        state: { title: this.backendTranslate(this.selectedCategory.name) },
      });
    }

    void this.modalService.dismiss();
  }

  closeModal(): void {
    void this.modalService.dismiss();
  }

  backendTranslate(translatable: Translatable): string {
    return this.languageService.translateBackendCopy(translatable);
  }
}
